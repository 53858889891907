import React from "react";
import { Link } from "gatsby";

const NewConsultation = () => (
  <Link to="/consultations/add">
    <button className="button is-primary is-medium">+ New Consultation</button>
  </Link>
);

export default NewConsultation;

import React, { useState, useContext, Fragment } from "react";
import { navigate } from "gatsby";

import DataTable from "../../Templates/DataTable";
import ConsultationRow from "./ConsultationRow";

import api from "../../../services/api";
import AppContext from "../../../context/AppContext";
import AdminConsultationRow from "../../Admin/Consultations/AdminConsultationRow";

const CONSULTATION_HEADERS = [
  "Created By",
  "Consult",
  "Employee",
  "Chief Complaint",
  "RN Assessment",
  "Recommendation",
];

const ADMIN_CONSULTATION_HEADERS = [
  "Created By",
  "Consult",
  "Employee",
  "Chief Complaint",
  "RN Assessment",
  "Recommendation",
  "Clinic Name",
];

const ConsultationTable = ({ consultations, isAdmin }) => {
  const [isLoading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  const RenderConsultations = () => (
    <DataTable headerTitles={CONSULTATION_HEADERS} isSelectable>
      {consultations.length > 0 ? consultations.map((consultation, index) => (
        <ConsultationRow
          consultation={consultation}
          key={index}
          callbackFunction={() => {
            setLoading(true);
            api.get(`consultations/${consultation.id}/`).then(response => {
              appContext.setActiveConsultation(response.data);
              navigate("/consultations/view");
            });
          }}
        />
      )) : (<tr><td colSpan={CONSULTATION_HEADERS.length} className="has-text-centered"><p className="my-1">No data available.</p></td></tr>)}
    </DataTable>
  );

  const RenderAdminConsultations = () => (
    <DataTable headerTitles={ADMIN_CONSULTATION_HEADERS} isSelectable>
      {consultations.length > 0 ? consultations.map((consultation, index) => (
        <AdminConsultationRow
          consultation={consultation}
          key={index}
          callbackFunction={() => {
            setLoading(true);
            api.get(`consultations/${consultation.id}/`).then(response => {
              appContext.setActiveConsultation(response.data);
              navigate("/admin/consultations/view");
            });
          }}
        />
        )) : (<tr><td colSpan={ADMIN_CONSULTATION_HEADERS.length} className="has-text-centered"><p className="my-1">No data available.</p></td></tr>)}
    </DataTable>
  );

  return (
    <Fragment>
      {isAdmin ? (
        <RenderAdminConsultations consultations={consultations} />
      ) : (
        <RenderConsultations consultations={consultations} />
      )}
    </Fragment>
  );
};

export default ConsultationTable;

import React from "react";
import moment from "moment";
import classNames from "classnames";
import { faLaptopMedical, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getOverflowClassnames = (data = "") => {
  if (data)
    return {
      tooltip: data.length > 25,
      "is-tooltip-multiline": data.length > 60,
    };
};

const getOverflowData = data => {
  if (data) return data.length > 25 ? `${data.slice(0, 25)}...` : data;
};

const AdminConsultationRow = ({ consultation, callbackFunction }) => {
  let {
    employee,
    account,
    chiefComplaint,
    recommendation,
    created,
    clinicName,
    consultType,
    createdBy,
    assessment,
    status,
    scheduled,
  } = consultation;

  // formatting for date and time
  let dateFormat = { month: "short", day: "numeric" };
  let dateCreated = new Date(Date.parse(created)).toLocaleDateString(
    [],
    dateFormat
  );
  const teleconsultDate = scheduled
    ? moment(scheduled).calendar(null, { sameElse: "MMM DD, h:mm A" })
    : "-";
  const consultTypeIcon =
    consultType === "Teleconsult" ? (
      <FontAwesomeIcon icon={faLaptopMedical} />
    ) : (
      <FontAwesomeIcon icon={faUser} />
    );

  return (
    <tr className="is-selectable" onClick={callbackFunction}>
      <td>
        {createdBy}
        <br />
        <p className="help">
          {dateCreated}, {moment(created).format("h:mm A")}
        </p>
      </td>
      <td>
        {status === "Scheduled" ? (
          teleconsultDate
        ) : (
          <p data-tooltip={consultType}>
            {consultTypeIcon} {consultType}
            <br />
            <p className="help">{clinicName}</p>
          </p>
        )}
      </td>
      <td>
        {employee}
        <p
          className={classNames("help", getOverflowClassnames(account))}
          data-tooltip={account}
        >
          {getOverflowData(account)}
        </p>
      </td>
      <td
        className={classNames(getOverflowClassnames(chiefComplaint))}
        data-tooltip={chiefComplaint}
      >
        {getOverflowData(chiefComplaint)}
      </td>
      <td
        className={classNames(getOverflowClassnames(assessment))}
        data-tooltip={assessment}
      >
        {getOverflowData(assessment)}
      </td>
      <td>{recommendation}</td>
      <td
        className={classNames(getOverflowClassnames(clinicName))}
        data-tooltip={clinicName}
      >
        {getOverflowData(clinicName)}
      </td>
    </tr>
  );
};

export default AdminConsultationRow;

import React from "react";
import moment from "moment";
import classNames from "classnames";
import { faLaptopMedical, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getOverflowClassnames = data => {
  let finalData = data ? data : "Other";
  return {
    tooltip: finalData.length > 25,
    "is-tooltip-multiline": finalData.length > 60,
  };
};

const getOverflowData = data => {
  let finalData = data ? data : "Other";
  return finalData.length > 25 ? `${finalData.slice(0, 25)}...` : finalData;
};

const ConsultationRow = ({ consultation, callbackFunction }) => {
  let {
    employee,
    account,
    chiefComplaint,
    recommendation,
    created,
    clinicName,
    consultType,
    createdBy,
    assessment,
    status,
    scheduled,
  } = consultation;

  // let { updatedBy, updated } = lastUpdated;

  // formatting for date and time
  let dateFormat = { month: "short", day: "numeric" };
  let dateCreated = new Date(Date.parse(created)).toLocaleDateString(
    [],
    dateFormat
  );
  // let timeCreated = new Date(Date.parse(created)).toLocaleTimeString()
  const teleconsultDate = scheduled
    ? moment(scheduled).calendar(null, { sameElse: "MMM DD, h:mm A" })
    : "-";
  const consultTypeIcon =
    consultType === "Teleconsult" ? (
      <FontAwesomeIcon icon={faLaptopMedical} />
    ) : (
      <FontAwesomeIcon icon={faUser} />
    );
  return (
    <tr className="is-selectable" onClick={callbackFunction}>
      <td>
        {createdBy}
        <br />
        <p className="help">
          {dateCreated}, {moment(created).format("h:mm A")}
        </p>
      </td>
      <td>
        {status === "Scheduled" ? (
          teleconsultDate
        ) : (
          <p data-tooltip={consultType}>
            {consultTypeIcon} {consultType}
            <br />
            <p className="help">{clinicName}</p>
          </p>
        )}
      </td>
      <td>
        {employee}
        <p
          className={classNames("help", getOverflowClassnames(account))}
          data-tooltip={account}
        >
          {getOverflowData(account)}
        </p>
      </td>
      <td
        className={classNames(getOverflowClassnames(chiefComplaint))}
        data-tooltip={chiefComplaint}
      >
        {getOverflowData(chiefComplaint)}
      </td>
      <td
        className={classNames(getOverflowClassnames(assessment))}
        data-tooltip={assessment}
      >
        {getOverflowData(assessment)}
      </td>
      <td>{recommendation}</td>
    </tr>
  );
};

export default ConsultationRow;

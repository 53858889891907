import React, { useState, useEffect } from "react";
import { Router, Location } from "@reach/router";

import ConsultationLogs from "../components/Consultation/ConsultationLogs/ConsultationLogs";
import Layout from "../layout/Layout";
import NewConsultation from "../components/Consultation/NewConsultationButton";
import SEO from "../layout/Seo";

export default () => {
  const [clinicId, setClinicId] = useState(null);
  const [partnerClinics, setPartnerClinics] = useState(null)
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setClinicId(window.sessionStorage.getItem("clinicId"));
      setPartnerClinics(window.sessionStorage.getItem("partnerClinics"));
    }
  }, []);

  return (
    <Layout
      hasBanner
      pageTitle="Consultations"
      hasRightButton={
        <div>
          <NewConsultation />\
        </div>
      }
    >
      <SEO title="Consultations" />
      <Location>
        {({ location }) => (
          <Router location={location}>
            <ConsultationLogs
              path={`${location.pathname}`}
              clinicId={clinicId}
              partnerClinics={partnerClinics}
            />
          </Router>
        )}
      </Location>
    </Layout>
  );
};

// 20200416 UPDATE
// Since the very same table is used in the Admin Panel but with the added functionality of being able to select which clinic to look at,
// made this component reusable by accepting clinicId props

import React, { useEffect, useState, useContext, Fragment } from "react"; // imported useState & useEffect so they can be used
import moment from "moment";
import classNames from "classnames";
import { Formik, Form } from "formik";

import SearchBar from "../../Templates/Forms/SearchBar";
import Input from "../../Templates/Forms/Input";

import AppContext from "../../../context/AppContext";
import Pagination from "../../Templates/Pagination";
import Loader from "../../Templates/Loader";
import api from "../../../services/api";
import ConsultationTable from "./ConsultationTable";
import ConsultationTabs from "./ConsultationTabs";

const ConsultationLogs = ({ partnerClinics, clientId, clinicId, isAdmin }) => {
  // create a useState to store the data using `consultations` and `setConsultations`
  const [consultations, setConsultations] = useState([]);

  const [isLoadingConsultations, setLoadingConsultations] = useState(false);
  const [pages, setPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const appContext = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("Completed");

  // ConsultationLogs is a reusable component. Sometimes it's accessed normally, or by the admin panel. So the API calls will have to vary
  // Since it's a hassle to do url/?search=`${searchQuery}` and the params vary in a lot of cases (clientId, clinicId, search, page)
  // what we do is initialize the parameters into an object (searchParams)
  const initialParams = isAdmin
    ? { client: "all", page: 1, status: "Completed" }
    : { mainClinic: clinicId, page: 1, status: "Completed" };
  const [searchParams, setSearchParams] = useState(initialParams);

  // ...and then we create a function that transforms this object into a legit URLSearchParams object we can append to the API call
  // since the 1) setLoading 2) convert object to legit URLSearchParams 3) setValues are repeating steps, let's package it as the fetchData function...
  const setValues = (consultations, pages) => {
    setConsultations(consultations);
    setPages(pages);
    setLoadingConsultations(false);
  };

  const fetchData = () => {
    setLoadingConsultations(true);
    if (searchParams.client || searchParams.mainClinic) {
      const objectToParams = new URLSearchParams(searchParams);
      api
        .get(`/consultations/report/?${objectToParams.toString()}`)
        .then(response => {
          setValues(response.data.results, Math.ceil(response.data.count / 30));
        });
    }
  };

  // After everything, this means that searchParams is now modifiable - just use setSearchParams, and we can easily modify it like we would with a normal JS object
  // We need to call setSearchParams when, for example, the clientId changes:
  useEffect(() => {
    if (!!clientId) {
      setSearchParams(prevParams => {
        if (clientId === "all") delete prevParams.mainClinic;
        return { ...prevParams, client: clientId, page: 1 };
      });
    }
  }, [clientId]);

  useEffect(() => {
    // ...or if clinicId changes
    if (!!clinicId) {
      setSearchParams(prevParams => {
        return { ...prevParams, mainClinic: clinicId, page: 1 };
      });
    }
  }, [clinicId]);

  useEffect(() => {
    if (!!partnerClinics) {
      setSearchParams(prevParams => {
        return { ...prevParams, clinic: partnerClinics }
      })
    }
  }, [partnerClinics])

  useEffect(() => {
    // ...when the active tab changes
    setSearchParams(prevParams => {
      const ordering = activeTab === "Completed" ? "-created" : "scheduled";
      return { ...prevParams, status: activeTab, ordering: ordering, page: 1 };
    });
  }, [activeTab]);

  // ...even the search query
  const searchData = search => {
    let date = moment(search, "MMMM D", true);
    setSearchQuery(search);
    if (date.isValid()) {
      setSearchParams(prevParams => {
        return { ...prevParams, search: date.format("YYYY-MM-DD"), page: 1 };
      });
    } else {
      setSearchParams(prevParams => {
        return { ...prevParams, search: search, page: 1 };
      });
    }
  };

  const resetSearch = values => {
    if (values.search) values.search = "";
    setSearchQuery("");
    setSearchParams(prevParams => {
      return { ...prevParams, search: "", page: 1 };
    });
  };

  // ...and let's not forget navigating between pages
  const navigatePages = pageNumber => {
    setSearchParams(prevParams => {
      return { ...prevParams, page: pageNumber };
    });
  };

  // FINALLY, we listen to changes in searchParams values => because that's our trigger to refresh data
  useEffect(() => fetchData(), [searchParams]);

  return (
    <Fragment>
      <Formik
        initialValues={{
          employee: "",
          search: "",
        }}
      >
        {({ values }) => (
          <Form autoComplete="off">
            <div className="columns is-vcentered">
              <div className="column is-8">
                <Input
                  isRequired
                  name="search"
                  value="search"
                  render={({ field, form, props }) => {
                    return (
                      <SearchBar
                        placeholder="Search for nurse on duty, employee, chief complaint, nurse assessment, or recommendation..."
                        endpoint="consultations/report"
                        field={field}
                        form={form}
                        props={props}
                        displayResults={false}
                        resultDisplay={["account", "chiefComplaint"]}
                        keyPressCallback={() => {
                          searchData(values.search);
                        }}
                        callbackFunction={result => {
                          searchData(result.id);
                        }}
                        isExpanded
                      >
                        <div className="control">
                          <button
                            name="search-button"
                            className="button is-primary"
                            onClick={() => {
                              searchData(values.search);
                            }}
                          >
                            Search
                          </button>
                        </div>
                        <div className="control">
                          <button
                            className={classNames("delete is-large", {
                              "is-invisible": values.search.length < 1,
                            })}
                            onClick={() => resetSearch(values)}
                            type="button"
                          />
                        </div>
                      </SearchBar>
                    );
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ConsultationTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {isLoadingConsultations ? (
        <Loader size={4} />
      ) : (
        <ConsultationTable consultations={consultations} isAdmin={isAdmin} />
      )}
      <br />
      {pages > 1 && (
        <Pagination pages={pages} callbackFunction={navigatePages} />
      )}
      <br />
    </Fragment>
  );
};

export default ConsultationLogs;

import React from "react";
import classNames from "classnames";

const ConsultationTabs = ({ setActiveTab, activeTab }) => {
  const ConsultationTab = ({ index, activeTab, label }) => {
    return (
      <li
        className={classNames({
          "is-active": activeTab === label,
        })}
        onClick={() => setActiveTab(label)}
      >
        <span>{label}</span>
      </li>
    );
  };

  const tabs = ["Completed", "Scheduled"];

  return (
    <div className={classNames("tabs is-boxed")}>
      <ul>
        {tabs.map((tab, index) => {
          return (
            <ConsultationTab
              label={tab}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              key={index}
              index={index}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default ConsultationTabs;
